import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel
} from '@microsoft/signalr';
import { NotificationEvents } from 'types/notification';

/**
 * @param {String} service The function-app service that the SignalR connection will be established with.
 * @param {Function} authFunc The function by whci h the auth token can be acquired.
 * @returns {HubConnection} service The function-app service that the SignalR connection will be established with.
 */
export const getConnection = (
  service: string,
  authFunc: () => string | Promise<string | void>,
  onRetryCallback: () => void | null
): HubConnection => {
  const authFuncString: () => Promise<string> = authFunc as () => Promise<string>;
  const connectionOptions: IHttpConnectionOptions = {
    accessTokenFactory: authFuncString
  }

  return new HubConnectionBuilder()
    .withUrl(
      `${process.env.REACT_APP_AGENTX_BASE_API}/${service}`,
      connectionOptions
    )
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: retryContext => {
        if (onRetryCallback != null && typeof (onRetryCallback) === 'function') {
          onRetryCallback();
        }

        // log details about the retry attempt and errors
        console.info(retryContext);

        if (retryContext.elapsedMilliseconds < 10000) {
          // for the first 10 seconds, we want to retry every second
          return 1000;
        } else if (retryContext.elapsedMilliseconds < 60000) {
          // between 10-60 seconds, we want to retry every 5 seconds
          return 5000;
        } else {
          // If we've been reconnecting for more than 60 seconds
          // we will start backing off to 10 seconds per retry
          return 10000;
        }
      }
    })
    .configureLogging(LogLevel.Information)
    .build()
}

/**
 * @param {HubConnection} connection The function that will process the SignalR message
 * @param {String} target The type of SignalR message that is to be targeted.
 * @param {Function} processFunc The function that will process the SignalR message
 */
export const receiveNotifications = <T extends NotificationEvents>(
  connection: HubConnection,
  target: string,
  processFunc: (msg: T) => void
) => {

  if (connection.state === HubConnectionState.Disconnected)
    connection.start()
      .catch(console.error)

  connection.off(target)
  connection.on(
    target,
    msg => {
      processFunc(msg)
    }
  )
}

/**
 * @param {HubConnection} connection The function that will process the SignalR message
 * @param {String} target The type of SignalR message that is to be targeted.
 */
export const stopNotifications = (
  connection: HubConnection,
  target: string,
) => {
  if (connection.state === HubConnectionState.Disconnected)
    connection.start()
      .catch(console.error)

  connection.off(target)
}