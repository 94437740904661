import CampaignsService from "services/campaignsService";
import { Campaign } from "types/campaigns"

const getCampaignsById = async (
    campaignId: string,
    getAuthToken: () => Promise<string | void>
): Promise<Campaign | null> => {
    const token = await getAuthToken()
    if (!token) return null;
  
    const service = new CampaignsService(token)
    if (!service) return null;
  
    const result = await service.campaignsService.get('GetCampaign', {
      CampaignId: campaignId,
    })
  
    return result.data
  }
  
  export default getCampaignsById