import {
  useState,
  useEffect,
  useCallback,
  useContext,
  Suspense,
  useMemo
} from 'react'
import { AgencyContext, UserContext } from '../../contexts'
import { ContentLayout, PageLayout } from '../../layout'
import {
  AgxRow, AgxColumn,
  AgxHeader, AgxLabel,
  AgxButton, AgxTextInput,
  AgxBodyText, AgxToast,
  AgxDivider, AgxFilterableTableHeader,
  Images,
  CampaignStage
} from '@urbanx/agx-ui-components'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useAzureAuth } from 'hooks/useAzureAuth'
import {
  GetAllAgencies, GetCampaignsForAgency,
  GetArchivedCampaigns, GetAgentsInAgency,
  BeginCampaign, PerformCampaignAction
} from 'services'
import { AgxToastState } from '../../types/commonTypes'
import {
  Campaign,
  CampaignTableColumn,
  CampaignTableColumnNames,
  CampaignColumnStatus,
  CampaignsTab,
  CampaignStageAction,
} from 'types/campaigns'
import { Agency, agentTypes } from 'types/agency'
import CampaignOptions from './components/options/CampaignOptions'
import { cleanTwoLineAddressWithoutState } from '../../helpers/address'
import { ReactComponent as Live } from './assets/live.svg'
import { ReactComponent as Offline } from './assets/offline.svg'
import placeHolderImage from 'assets/images/placeholder-property.png'
import './Campaigns.scss'
import { MenuTitles } from 'constants/menu'
import { getEnumValue } from 'helpers/enumHelper'

const CampaignsPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const [searchText, setSearchText] = useState('')
  const [currentTab, setCurrentTab] = useState(CampaignsTab.Campaigns)
  const [shouldResetFilters, setShouldResetFilters] = useState(false)
  const [allCampaignsData, setAllCampaignsData] = useState<Campaign[]>([])
  const [allArchivedCampaignsData, setAllArchivedCampaignsData] = useState<Campaign[]>([])
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const navigate = useNavigate()
  const [, getAuthToken] = useAzureAuth()

  interface Column {
    id: string
    name: string
    options: string[] | object[]
  }

  const [columns, setColumns] = useState<Column[]>([])
  const [filteredData, setFilteredData] = useState<Campaign[]>([])

  const queryKeys = {
    campaigns: [`campaigns-${agencyId}`, agencyId],
    archivedCampaigns: [`archivedCampaigns-${agencyId}`, agencyId],
    agentsInAgency: [`agentsInAgency-${agencyId}`, agencyId],
  }

  const { data: campaigns, isLoading: isLoadingCampaigns } =
    useQuery<Campaign[] | undefined>({
      queryKey: queryKeys.campaigns,
      queryFn: (queryKey) => GetCampaignsForAgency(queryKey, getAuthToken),
    })

  const { data: archivedCampaigns, isLoading: isLoadingArchivedCampaigns } =
    useQuery<Campaign[] | undefined>({
      queryKey: queryKeys.archivedCampaigns,
      queryFn: (queryKey) => GetArchivedCampaigns(queryKey, getAuthToken),
    })

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const { data: agents } = useQuery<agentTypes[] | undefined>({
    queryKey: queryKeys.agentsInAgency,
    queryFn: (queryKey) => GetAgentsInAgency(queryKey, getAuthToken),
  })

  const toastMessage = (action: CampaignStageAction) => {
    switch (action) {
      case CampaignStageAction.Archive:
        return 'Campaign moved to Archive'
      case CampaignStageAction.Restore:
        return 'Campaign restored successfully'
      case CampaignStageAction.MarkAsSold:
        return 'Campaign status updated to Sold'
      case CampaignStageAction.MarkAsUnderContract:
        return 'Campaign status updated to Under Contract'
      case CampaignStageAction.Withdraw:
        return 'Campaign status updated to Withdrawn'
      case CampaignStageAction.RevertToCurrent:
        return 'Campaign status updated to Current'
      default:
        return 'Campaign action performed successfully'
    }
  }

  const { mutate: setPerformCampaignAction } = useMutation(PerformCampaignAction, {
    onSuccess: (_, { campaignStageAction: action }) => {      
      Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKeys.campaigns }),
        queryClient.invalidateQueries({ queryKey: queryKeys.archivedCampaigns })
      ])
      updateToastState({
        color: 'success',
        message: toastMessage(action),
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error performing campaign action',
        open: true,
      })
    },
  })

  const isArchivedTab = currentTab !== CampaignsTab.Campaigns

  useEffect(() => {
    if (!selectedAgency) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies])

  const getAllAgentNames = useMemo((): string[] => {
    return (agents || [])
      .filter((agent) => agent?.name?.firstName && agent?.name?.lastName)
      .map((agent) => `${agent.name?.firstName} ${agent.name?.lastName}`)
  }, [agents])

  const getAgentNameByEmailId = useCallback(
    (emailId: string) => {
      const agent = agents?.find((agent) => agent.id === emailId)?.name
      return agent ? `${agent?.firstName} ${agent?.lastName}` : '--'
    },
    [agents]
  )

  const filterCampaigns = (
    data: Campaign[] | undefined,
    searchText: string
  ): Campaign[] => {
    return data
      ? data.filter((campaign) => campaign.address?.formattedAddress
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
      ) : []
  }

  useEffect(() => {
    const data = isArchivedTab ? archivedCampaigns : campaigns;
    const filteredData = searchText ? filterCampaigns(data, searchText) : data;

    if (filteredData){
      if (!isArchivedTab)
        setAllCampaignsData(filteredData);
      else
        setAllArchivedCampaignsData(filteredData);
  
      setFilteredData(filteredData);
    }
    
    const allData = !isArchivedTab ? campaigns : archivedCampaigns || []

    const uniqueStatusOptions = Array.from(
      new Set(allData?.map((row) => row.stage))
    )

    // Update columns with filtering options
    const updatedColumns: Column[] = [
      {
        id: CampaignTableColumn.Campaign,
        name: CampaignTableColumnNames.Campaign,
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: CampaignTableColumn.Status,
        name: CampaignTableColumnNames.Status,
        options: !isArchivedTab
          ? [
            CampaignColumnStatus.Agreement,
            CampaignColumnStatus.CurrentListing,
            CampaignColumnStatus.UnderContract,
          ]
          : uniqueStatusOptions,
      },
      {
        id: CampaignTableColumn.LeadAgent,
        name: CampaignTableColumnNames.LeadAgent,
        options: getAllAgentNames,
      },
      {
        id: CampaignTableColumn.SecondAgent,
        name: CampaignTableColumnNames.SecondAgent,
        options: getAllAgentNames,
      },
      {
        id: CampaignTableColumn.BlankContract,
        name: CampaignTableColumnNames.BlankContract,
        options: !isArchivedTab
          ? [CampaignColumnStatus.Live, CampaignColumnStatus.Offline]
          : [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
    ]

    setColumns(updatedColumns)
  }, [searchText, campaigns, archivedCampaigns, isArchivedTab, allCampaignsData]) 

  const applyFilters = (filters: { [key: string]: string[] }) => {
    const dataToFilter = isArchivedTab
      ? allArchivedCampaignsData
      : allCampaignsData

    if (!dataToFilter) {
      setFilteredData([])
      return
    }    

    // Apply filters to the data
    const filteredRows = dataToFilter.filter((row) =>
    Object.entries(filters).every(([column, selectedValues]) => {
        if (column === CampaignTableColumn.BlankContract) {
          const blankContractStatus = row.blankContractIsLive ?
            CampaignColumnStatus.Live :
            CampaignColumnStatus.Offline

          return (
            selectedValues.length === 0 ||
            selectedValues.some((value) => blankContractStatus === value)
          )
        }

        if (column === CampaignTableColumn.Status && !isArchivedTab) {
          if (row.stage === CampaignStage.AgreementDraft || row.stage === CampaignStage.AgreementAgentSigning ||
              row.stage === CampaignStage.AgreementVendorSigning || row.stage === CampaignStage.AgreementSignedByAllParties)
            return (
              selectedValues.length === 0 ||
              selectedValues.includes(CampaignColumnStatus.Agreement)
            )
          if (row.stage === CampaignStage.ListingCurrent)
            return (
              selectedValues.length === 0 ||
              selectedValues.includes(CampaignColumnStatus.CurrentListing)
            )
          if (row.stage === CampaignStage.ListingUnderContract)
            return (
              selectedValues.length === 0 ||
              selectedValues.includes(CampaignColumnStatus.UnderContract)
            )
        }

        const cellValue =
          column === CampaignTableColumn.LeadAgent ||
            column === CampaignTableColumn.SecondAgent
            ? getAgentNameByEmailId(row[column])
            : column.includes('.')
              ? column.split('.').reduce((obj, key) => (obj as any)?.[key], row)
              : (row as any)[column]

        const stringCellValue = String(cellValue).toLowerCase()

        return (
          selectedValues.length === 0 ||
          selectedValues.some(
            (value) => stringCellValue === value.toLowerCase()
          )
        )
      })
    )

    setFilteredData(filteredRows)
  }

  const onShowSubmissions = (campaign: Campaign) => {
    navigate(`/${agencyId}/campaigns/${campaign.id}/files`)
  }

  const onPerformCampaignAction = (campaign: Campaign, campaignAction: CampaignStageAction) => {
    setPerformCampaignAction({
      getAuthToken,
      campaignId: campaign.id,
      campaignStageAction: campaignAction
    })
  }

  const { mutate: beginCampaignValues } = useMutation(BeginCampaign, {
    onSuccess: (data) => {
      if (data?.campaingId) {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: [`campaigns-${agencyId}`, agencyId],
          }),
        ])
        if (!isLoadingCampaigns) {
          navigate(`/${agencyId}/campaigns/${data?.campaingId}/manage`)
        }
      }
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error begin campaign',
        open: true,
      })
    },
  })

  const onBeginCampaign = () => {
    if (!agencyId) return
    beginCampaignValues({ AgencyId: agencyId, getAuthToken})
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Campaigns"
    >
      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.CAMPAIGNS}>
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn>
            <AgxRow spaceBetween centered extraClasses="filters__campaigns">
              <AgxRow extraClasses="filterTabs__campaigns">
                <AgxButton
                  text="All Campaigns"
                  large
                  hollow
                  onClick={() => {
                    setCurrentTab(CampaignsTab.Campaigns)
                    setShouldResetFilters(true)
                  }}
                  extraClasses={`currentTab ${isArchivedTab ? 'formerTab' : ''}`}
                  naked
                />
                <AgxButton
                  text="Archived"
                  large
                  hollow
                  onClick={() => {
                    setCurrentTab(CampaignsTab.Archived)
                    setShouldResetFilters(true)
                  }}
                  extraClasses={`currentTab ${!isArchivedTab ? 'formerTab' : ''}`}
                  naked
                />
              </AgxRow>
              <AgxRow>
                <AgxRow>
                  <AgxTextInput
                    id="campaignSearch"
                    label=""
                    defaultValue={searchText}
                    onInputValueChange={({ value }: { value: string }) =>
                      setSearchText(value)
                    }
                    noHeader
                    noOptionalLabel
                    placeholder="Search"
                    leftIcon={<Images.SearchOutline />}
                    extraClasses="searchText__campaigns"
                  />
                  <AgxButton
                    text="New Campaign"
                    medium
                    hollow
                    leftIcon={!isLoadingCampaigns ? <Images.Plus /> : <Images.Loader />}
                    onClick={onBeginCampaign}
                  />
                </AgxRow>
              </AgxRow>
            </AgxRow>
            <AgxDivider />
            <AgxRow>
              <table
                className="campaignsTable"
                width={'100%'}
                style={{ paddingBottom: 30 }}
              >
                <tbody>
                  <AgxFilterableTableHeader
                    columns={columns}
                    shouldResetFilters={shouldResetFilters}
                    setShouldResetFilters={setShouldResetFilters}
                    onFilterChange={applyFilters}
                  />
                  {isLoadingCampaigns || isLoadingArchivedCampaigns ? (
                    <Suspense>
                      <tr>
                        <td colSpan={11}>
                          <AgxLabel extraClasses="loadingText_campaigns">
                            Loading Campaigns...
                          </AgxLabel>
                        </td>
                      </tr>
                    </Suspense>
                  ) : (
                    <>
                      {filteredData?.map((campaign, index) => {
                        const [addressLineOne, addressLineTwo] =
                          cleanTwoLineAddressWithoutState(campaign.address)

                        return (
                          <tr key={index} className="tableRowStyle">
                            <td colSpan={3} align="left" onClick={() => onShowSubmissions(campaign)}>
                              <img
                                className={`propertyImage${campaign.completionState?.completionType ? ' archived' : ''}`}
                                width={60}
                                src={ campaign.imageUrls?.large ?? placeHolderImage }
                              />
                            </td>
                            <td colSpan={3} align="left" onClick={() => onShowSubmissions(campaign)}>
                              <AgxHeader size={4}>
                                {addressLineOne ? 
                                  <>{addressLineOne},<br /></> :
                                  <>Address <br />Unidentified</>
                                }
                                {addressLineTwo ?? ''}
                              </AgxHeader>
                            </td>
                            <td align="left" onClick={() => onShowSubmissions(campaign)}>
                              <AgxBodyText small>
                                {getEnumValue(CampaignStage, campaign.stage)}
                              </AgxBodyText>
                            </td>
                            <td align="left" onClick={() => onShowSubmissions(campaign)}>
                              <span className="iconLabel">
                                <Images.PersonOutline />
                                {getAgentNameByEmailId(
                                  campaign.leadAgentId
                                ) && (
                                    <AgxBodyText small>
                                      {getAgentNameByEmailId(
                                        campaign.leadAgentId
                                      )}
                                    </AgxBodyText>
                                  )}
                              </span>
                            </td>
                            <td align="left" onClick={() => onShowSubmissions(campaign)}>
                              <span className="iconLabel">
                                <Images.PeopleOutline />
                                {getAgentNameByEmailId(
                                  campaign.secondAgentId
                                ) && (
                                    <AgxBodyText small>
                                      {getAgentNameByEmailId(
                                        campaign.secondAgentId
                                      )}
                                    </AgxBodyText>
                                  )}
                              </span>
                            </td>
                            <td align="left">
                              {isArchivedTab && (
                                <AgxButton
                                  text="Restore"
                                  medium
                                  hollow
                                  onClick={() => onPerformCampaignAction(campaign, CampaignStageAction.Restore)}
                                  extraClasses="restoreButton"
                                />
                              )}
                              {!isArchivedTab &&
                                (campaign?.blankContractIsLive ? (
                                  <>
                                    <Live />
                                    <AgxBodyText extraClasses="contractStatus">Live</AgxBodyText>
                                  </>
                                ) : (
                                  <>
                                    <Offline />
                                    <AgxBodyText neutralGrayColor extraClasses="contractStatus">Offline</AgxBodyText>
                                  </>
                                ))}
                            </td>
                            <td align="left" valign="middle" className="options">
                              <CampaignOptions
                                key={campaign.id}
                                selectedCampaign={campaign}
                                isArchived={isArchivedTab}
                                onPerformCampaignAction={onPerformCampaignAction}
                              />
                            </td>
                          </tr>
                        )
                      })}
                      {filteredData.length === 0 && (
                        <tr>
                          <td colSpan={11}>
                            <AgxLabel extraClasses="loadingText_campaigns">No Campaigns found</AgxLabel>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </AgxRow>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default CampaignsPage
