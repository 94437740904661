import { FormDocumentSubmissionDetails } from './campaigns'
import { CampaignStage, UploadedDocument } from "@urbanx/agx-ui-components"

export enum ConnectionState {
  NotConnected,
  Connecting,
  Connected,
  Reconnecting,
  Disconnected,
}

export enum NotificationType {
  PdfProcessedEvent = "PdfProcessedEvent",
  EnvelopeUpdatedEvent = "EnvelopeUpdatedEvent",
  ReadyForDocuSignEvent = "ReadyForDocuSignEvent",
}

export interface Notification {
  connectionState: ConnectionState
  connectionUpdatedAt: Date
  registerEvent: ((eventType: string, callback: (event: NotificationEvents) => void) => void) | null
  unregisterEvent: ((eventType: string) => void) | null
}

export interface PdfProcessedEvent {
  campaignId: string;
  formId: string;
  uploadedDocument: UploadedDocument;
}

export interface EnvelopeUpdatedEvent {
  id: string,
  agencyAgreementSubmission: FormDocumentSubmissionDetails
  campaignStage: CampaignStage
}

export type NotificationEvents = PdfProcessedEvent & EnvelopeUpdatedEvent